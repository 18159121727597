import React from "react";
import { BrowserRouter } from "react-router-dom";
import CssBaseLine from "@mui/material/CssBaseline";
import routes, { RenderRoutes } from "./routes";

function App() {
  return (
    <>
      <CssBaseLine />
      <BrowserRouter>
        <RenderRoutes routes={routes} />
      </BrowserRouter>
    </>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import flag from "../../assets/img/tunisian-flag-2.png";
import { getSearchedArticles } from "../../slices/articles";
import {
  getWebsiteIdFromTxtRecord,
  getWebsiteInfo,
} from "../../slices/websites";
import { generateLighterShade } from "../../utilities/lighterShade";
export default function Header() {
  const { searchedArticles, searchedArticlesStatus } = useSelector(
    (state) => state.articles
  );

  const { websiteID, websiteInfo } = useSelector((state) => state.websites);

  const dispatch = useDispatch();
  const [display, setDisplay] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [secondColor, setSecondColor] = useState("");

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setDisplay(false);
    } else {
      dispatch(getSearchedArticles({ websiteID, key: e.target.value }));
      setDisplay(true);
    }
  };

  useEffect(() => {
    if (websiteID?.length == 0) {
      dispatch(getWebsiteIdFromTxtRecord());
    }
    if (websiteID && Object.keys(websiteInfo).length === 0) {
      dispatch(getWebsiteInfo(websiteID));
    }

    if (websiteInfo?.color) {
      setSecondColor(generateLighterShade(websiteInfo?.color, 30));
    }
  }, [dispatch, websiteID, websiteInfo]);

  return (
    <header
      style={{
        backgroundImage: websiteInfo?.color ? `linear-gradient(125deg, ${websiteInfo?.color} -10%, ${secondColor} 100%)` : "linear-gradient(125deg, #E1AE00 -10%, #A37E00 100%)",
      }}
    >
      <div className="header-main">
        <div className="header-main-logo">Support {websiteInfo?.name}</div>
        <div className="header-main-actions">
          <div className="header-main-actions-languages">
            <KeyboardArrowDownIcon />
            <img src={flag} alt="" />
          </div>
          <a href={websiteInfo?.url} target="_blank">
            <div className="header-main-actions-go">الذهاب للموقع</div>
          </a>
        </div>
      </div>
      <div className="header-title">كيف يمكن أن نساعدك ؟</div>
      <div className="header-search" style={{ position: "relative" }}>
        <div className="header-search-input">
          <input
            id="search"
            type="text"
            placeholder="البحث في مركز المساعدة..."
            onChange={handleChange}
            value={searchValue}
          />
          {searchedArticlesStatus === "loading" ? (
            <CircularProgress
              style={{
                marginRight: "10px",
                fontSize: "25px",
                fontWeight: "bold",
                width: "16.67px",
                height: "16.67px",
              }}
            />
          ) : (
            <SearchIcon
              style={{
                marginRight: "10px",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            />
          )}
        </div>
        {display && (
          <div className="header-search-results">
            {searchedArticles?.length === 0 ? (
              <div className="header-search-no-results-item">
                <div className="header-search-results-title">
                  عذرًا، لم يتم العثور على أي نتائج للبحث. جرب البحث بكلمات أخرى
                </div>
              </div>
            ) : (
              searchedArticles?.map((article) => (
                <Link
                  to={`/article/${article.slug}`}
                  state={{ id: article._id }}
                  onClick={() => {
                    setDisplay(false);
                    setSearchValue("");
                  }}
                  key={article._id}
                >
                  <div className="header-search-results-item">
                    <div className="header-search-results-title">
                      {article?.title}
                    </div>
                  </div>
                </Link>
              ))
            )}
          </div>
        )}
      </div>
    </header>
  );
}

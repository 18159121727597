import React from "react";
import { useSelector } from "react-redux";
import NotFound from "../pages/NotFound";
import Loading from "../pages/Loading";

const WebsiteIdGuard = ({ children }) => {
  const { websiteID, websiteIDStatus, websiteInfoStatus, websiteInfo } =
    useSelector((state) => state.websites);
  if (websiteIDStatus === "loading" || websiteInfoStatus === "loading") {
    return <Loading />;
  } else if (
    (websiteID?.length == 0 && websiteIDStatus === "succeeded") ||
    (!websiteID && websiteIDStatus === "succeeded") ||
    websiteInfoStatus == "failed" || websiteIDStatus === "failed"
  ) {
    return <NotFound />;
  } else {
    return <>{children}</>;
  }
};

export default WebsiteIdGuard;
import React, { Suspense, Fragment, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import WebsiteIdGuard from "./components/WebsiteIdGuard";
import MainLayout from "./layouts/MainLayout";
import Loading from "./pages/Loading";

export const RenderRoutes = ({ routes }) => (
  <Suspense fallback={<Loading />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const MainLayout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <MainLayout>
                  <Component />
                </MainLayout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);
export const routes = [
  {
    path: "/",
    layout: MainLayout,
    guard: WebsiteIdGuard,
    component: lazy(() => import("./pages/LandingPage")),
  },
  {
    path: "/article/:slug",
    layout: MainLayout,
    guard: WebsiteIdGuard,
    component: lazy(() => import("./pages/Article")),
  },
  {
    path: "/category/:slug",
    layout: MainLayout,
    guard: WebsiteIdGuard,
    component: lazy(() => import("./pages/Category")),
  },
  {
    path: "*",
    component: lazy(() => import("./pages/NotFound")),
  },

];
export default routes;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { getAllArticles } from "./articles";

const initialState = {
  categories: [],
  categoriesStatus: "idle",
  categoriesError: null,
};

export const getAllCategories = createAsyncThunk(
  "categories/getAllCategories",
  async (websiteID, thunkApi) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${websiteID}/articles-category`
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkApi.dispatch(getAllArticles(websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);


const slice = createSlice({
    name: "categories",
    initialState,
    reducers: {},
    extraReducers: {
      [getAllCategories.pending]: (state) => {
        state.categoriesStatus = "loading";
      },
      [getAllCategories.fulfilled]: (state, action) => {
        state.categoriesStatus = "succeeded";
        state.categories = action.payload;
      },
      [getAllCategories.rejected]: (state, action) => {
        state.categoriesStatus = "failed";
        state.categoriesError = action.payload;
      },
    },
  });
  export const reducer = slice.reducer;
  export default slice;
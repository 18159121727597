import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  articles: [],
  articlesStatus: "idle",
  articlesError: null,

  article: [],
  articleStatus: "idle",
  articleError: null,

  searchedArticles: [],
  searchedArticlesStatus: "idle",
  searchedArticlesError: null,

  rateArticleStatus: "idle",
  rateArticleError: null,
};

export const getAllArticles = createAsyncThunk(
  "articles/getAllArticles",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${websiteID}/articles?status=VISIBLE`
      );
      data = response.data.data.docs;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getSearchedArticles = createAsyncThunk(
  "articles/search",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteID}/articles/search/${body.key}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getOneArticle = createAsyncThunk(
  "articles/getOneArticle",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteID}/articles/slug/${body.slug}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const rateArticle = createAsyncThunk(
  "articles/rateArticle",
  async (body, thunkApi) => {
    let data;
    try {
      const response = await axios.patch(
        `/websites/${body.websiteID}/articles/${body.articleID}/rate`,
        body.rating 
      );
      data = response.data.data;
      if (response.status === 200) {
        // thunkApi.dispatch(changeLastMessageinConversation(data));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "articles",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllArticles.pending]: (state) => {
      state.articlesStatus = "loading";
    },
    [getAllArticles.fulfilled]: (state, action) => {
      state.articlesStatus = "succeeded";
      state.articles = action.payload;
    },
    [getAllArticles.rejected]: (state, action) => {
      state.articleStatus = "failed";
      state.articleError = action.payload;
    },

    [getOneArticle.pending]: (state) => {
      state.articleStatus = "loading";
    },
    [getOneArticle.fulfilled]: (state, action) => {
      state.articleStatus = "succeeded";
      state.article = action.payload;
    },
    [getOneArticle.rejected]: (state, action) => {
      state.articleStatus = "failed";
      state.articleError = action.payload;
    },

    [getSearchedArticles.pending]: (state) => {
      state.searchedArticlesStatus = "loading";
    },
    [getSearchedArticles.fulfilled]: (state, action) => {
      state.searchedArticlesStatus = "succeeded";
      state.searchedArticles = action.payload;
    },
    [getSearchedArticles.rejected]: (state, action) => {
      state.searchedArticlesStatus = "failed";
      state.searchedArticlesError = action.payload;
    },


    [rateArticle.pending]: (state) => {
      state.rateArticleStatus= "loading";
    },
    [rateArticle.fulfilled]: (state, action) => {
      state.rateArticleStatus = "succeeded";
      // state.messages = state.messages.map((message) =>
      //   message._id === action.payload._id ? action.payload : message
      // );
      // state.messagesStored = state.messagesStored.map((message) =>
      //   message._id === action.payload._id ? action.payload : message
      // );
    },
    [rateArticle.rejected]: (state, action) => {
      state.rateArticleStatus = "failed";
      state.rateArticleError = action.payload;
    },

  },
});
export const reducer = slice.reducer;
export default slice;

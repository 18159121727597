import { combineReducers } from "@reduxjs/toolkit";
import { reducer as articlesReducer } from "../slices/articles";
import { reducer as categoriesReducer } from "../slices/categories";
import { reducer as websitesReducer } from "../slices/websites";

const combinedReducer = combineReducers({
  articles: articlesReducer,
  categories: categoriesReducer,
  websites: websitesReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return combinedReducer(state, action);
};
export default rootReducer;

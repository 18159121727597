import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  websiteID: "",
  websiteIDStatus: "idle",
  websiteIDError: null,
  websiteInfo: {},
  websiteInfoStatus: "idle",
  websiteInfoError: null,
};

export const getWebsiteIdFromTxtRecord = createAsyncThunk(
  "websites/getWebsiteIdFromTxtRecord",
  async () => {
    let data;
    try {
      const response = await axios.get(
        `/owner/websites/website-id-from-txt-record`
      );
      data = response?.data?.data?.txtRecords[0][0]?.split('=')[1];
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getWebsiteInfo = createAsyncThunk(
  "websites/getWebsiteInfo",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(
        `/owner/websites/${websiteID}/helpdesk`
      );
      data = response?.data?.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
    name: "websites",
    initialState,
    reducers: {},
    extraReducers: {
      //Website ID
      [getWebsiteIdFromTxtRecord.pending]: (state) => {
        state.websiteIDStatus = "loading";
      },
      [getWebsiteIdFromTxtRecord.fulfilled]: (state, action) => {
        state.websiteIDStatus = "succeeded";
        state.websiteID = action.payload;
      },
      [getWebsiteIdFromTxtRecord.rejected]: (state, action) => {
        state.websiteIDStatus = "failed";
        state.websiteIDError = action.payload;
      },

      //Website Info
      [getWebsiteInfo.pending]: (state) => {
        state.websiteInfoStatus = "loading";
      },
      [getWebsiteInfo.fulfilled]: (state, action) => {
        state.websiteInfoStatus = "succeeded";
        state.websiteInfo = action.payload;
      },
      [getWebsiteInfo.rejected]: (state, action) => {
        state.websiteInfoStatus = "failed";
        state.websiteInfoError = action.payload;
      },
    },
  });
  export const reducer = slice.reducer;
  export default slice;
// Function to generate a lighter shade of a given color in hexadecimal format
export function generateLighterShade(color, percentage) {
    const red = parseInt(color.substr(1, 2), 16);
    const green = parseInt(color.substr(3, 2), 16);
    const blue = parseInt(color.substr(5, 2), 16);
  
    // Calculate the new RGB values based on the percentage
    const newRed = Math.max(0, red - (red * percentage) / 100);
    const newGreen = Math.max(0, green - (green * percentage) / 100);
    const newBlue = Math.max(0, blue - (blue * percentage) / 100);
  
    // Convert the new RGB values back to hexadecimal format
    const newColor = `#${Math.round(newRed).toString(16).padStart(2, '0')}${Math.round(newGreen).toString(16).padStart(2, '0')}${Math.round(newBlue).toString(16).padStart(2, '0')}`;
  
    return newColor;
  }
  
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmailIcon from "@mui/icons-material/Email";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import { Divider } from "@mui/material";
import {
  getWebsiteIdFromTxtRecord,
  getWebsiteInfo,
} from "../../slices/websites";

const Footer = () => {
  const { websiteID, websiteInfo } = useSelector((state) => state.websites);
  const takiChatUrl = process.env.REACT_APP_TAKICHAT_URL;
  const dispatch = useDispatch();

  useEffect(() => {
    if (websiteID?.length == 0) {
      dispatch(getWebsiteIdFromTxtRecord());
    }
    if (websiteID.length !== 0 && Object.keys(websiteInfo).length === 0) {
      dispatch(getWebsiteInfo(websiteID));
    }
  }, [dispatch, websiteID]);

  return (
    <footer>
      <Divider />
      <div className="footer-main">
        <div className="footer-title">
          <h4>لم تجد ماتبحث عنه؟</h4>
        </div>
        <div className="footer-text">
          تحدث معنا أو تواصل عبر البريد الإلكتروني
        </div>
        <div className="footer-buttons">
          <div className="footer-buttons-chat">
            <ModeCommentIcon style={{ padding: "0" }} />
            <span className="footer-buttons-chat-text">تحدث معنا</span>
          </div>

          <a href={`mailto:${websiteInfo?.email}`}>
            <div className="footer-buttons-mail">
              <EmailIcon />
              <span className="footer-buttons-mail-text">
                تواصل معنا عبر البريد الإلكتروني
              </span>
            </div>
          </a>
        </div>
      </div>
      <Divider />
      <div className="footer-copyright">
        <p>Support {websiteInfo?.name} 2022 ©</p>
        <p>
          We run on <a href={takiChatUrl} target="_blank" style={{textDecoration: "underline", cursor: "pointer"}}>TakiChat</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
